<template>
  <a href="https://edgeguardthegame.com" target="_blank"><img alt="logo" class="logo" src="./assets/edgeguard-logo.png"></a>
  <GameTrackers/>
  <p class="notice">This application uses local storage to save game tracker data.  You must have it enabled in your settings to save any data from one session to another.  Clearing your cache will remove this data.</p>
  <p class="notice">If you do not get a notice to install the application on your device, you can manually install it via the browser share button, then adding it to your home screen.</p>
</template>

<script>
import GameTrackers from './components/GameTrackers.vue'
export default {
    name: 'App',
    components: {
        GameTrackers
    }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #FFF;
  position: relative;
  margin: 0;
  padding: 5rem 0;
}
.logo {
    position: absolute;
    max-width: 220px;
    top: 0rem;
    right: 2rem;
}
body {
    background-color: #000;
}
.notice {
    font-size: 1rem;
    font-style: italic;
    padding: 0 1rem;
    text-align: center;
}
.notice:before {
    content: '* ';
    max-width: 80%;
}
@media screen and (max-width:600px){
    .logo {
            left: 2rem;
            right: 0;
            margin: auto;
        }
    .life-trackers {
        margin-bottom: -3rem;
    }
}
</style>
