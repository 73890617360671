<template>
  <div class="life-trackers flex">
    <div class="selector">
        <a href="#hero-tracker"><img class="tracker-icon" src="../assets/sword.png" alt="Hero Tracker" title="Hero Tracker"></a>
        <a href="#boss-tracker"><img class="tracker-icon" src="../assets/skull.png" alt="Boss Tracker" title="Boss Tracker"></a>
        <a href="#enemy-tracker"><img class="tracker-icon" src="../assets/skull-reverse.png" alt="Enemy Trackers" title="Enemy Trackers"></a>
    </div>
    <section v-for="(tracker, index) in params.values.herotrackers" :key="index" :id="'hero-tracker' + index" :class="(showTracker(index) == 0) ? 'hero-tracker tracker hide' : 'hero-tracker tracker'">
        <img class="tracker-icon" src="../assets/health.png" alt="Health" title="Health">
        <div class="health tracker-section">
            <button class="decrease-number number-change" :data-index="index" data-parameter="health" v-on:click="decreaseNumber">-</button>
            <input v-model.number="params.values.herotrackers[index].health" type="number" pattern="\d*" v-on:focus="select" v-on:change="pushToLocalStorage" id="health" class="tracker_input">
            <button class="increase-number number-change" :data-index="index" data-parameter="health" v-on:click="increaseNumber">+</button>
        </div>
        <img class="tracker-icon" src="../assets/resistance.png" alt="Resistance" title="Resistance">
        <div class="resistance tracker-section">
            <button class="decrease-number number-change" :data-index="index" data-parameter="resistance" v-on:click="decreaseNumber">-</button>
            <input v-model.number="params.values.herotrackers[index].resistance" type="number" pattern="\d*" v-on:focus="select" v-on:change="pushToLocalStorage" id="resistance" class="tracker_input">
            <button class="increase-number number-change" :data-index="index" data-parameter="resistance" v-on:click="increaseNumber">+</button>
        </div>
        <img class="tracker-icon" src="../assets/resource.png" alt="Resource" title="Resource">
        <div class="resource tracker-section">
            <button class="decrease-number number-change" :data-index="index" data-parameter="resource" v-on:click="decreaseNumber">-</button>
            <input v-model.number="params.values.herotrackers[index].resource" type="number" pattern="\d*" v-on:focus="select" v-on:change="pushToLocalStorage" id="resource" class="tracker_input">
            <button class="increase-number number-change" :data-index="index" data-parameter="resource" v-on:click="increaseNumber">+</button>
        </div>
        <img class="tracker-icon" src="../assets/enemy-kills.png" alt="Enemy Kills" title="Enemy Kills">
        <div class="experience tracker-section">
            <button class="decrease-number number-change" :data-index="index" data-parameter="kills" v-on:click="decreaseNumber">-</button>
            <input v-model.number="params.values.herotrackers[index].kills" type="number" pattern="\d*" v-on:focus="select" v-on:change="pushToLocalStorage" id="kills" class="tracker_input">
            <button class="increase-number number-change" :data-index="index" data-parameter="kills" v-on:click="increaseNumber">+</button>
        </div>
        <button :class="showButton(index, 'remove') == 0 ? 'remove-tracker hide add-remove-tracker' : 'remove-tracker add-remove-tracker'" :data-index="index" v-on:click="removeTracker">-</button>
        <button :class="showButton(index, 'add') == 0 ? 'add-tracker hide add-remove-tracker' : 'add-tracker add-remove-tracker'" :data-index="index" v-on:click="addTracker">+</button>
    </section>
    <section id="boss-tracker" class="boss-tracker tracker">
        <img class="tracker-icon" src="../assets/health.png" alt="Health" title="Health">
        <div class="health tracker-section">
            <button class="decrease-number number-change" data-parameter="health" v-on:click="decreaseNumber">-</button>
            <input v-model.number="params.values.bosstrackers[0].health" type="number" pattern="\d*" v-on:focus="select" v-on:change="pushToLocalStorage" id="health_boss" class="tracker_input">
            <button class="increase-number number-change" data-parameter="health" v-on:click="increaseNumber">+</button>
        </div>
        <img class="tracker-icon" src="../assets/resistance.png" alt="Resistance" title="Resistance">
        <div class="resistance tracker-section">
            <button class="decrease-number number-change" data-parameter="resistance" v-on:click="decreaseNumber">-</button>
            <input v-model.number="params.values.bosstrackers[0].resistance" type="number" pattern="\d*" v-on:focus="select" v-on:change="pushToLocalStorage" id="resistance_boss" class="tracker_input">
            <button class="increase-number number-change" data-parameter="resistance" v-on:click="increaseNumber">+</button>
        </div>
    </section>
    <section id="enemy-tracker" class="enemy-tracker tracker">
        <div v-for="(line, index) in params.enemyLines" :key="index"  class="enemy flex">
            <div class="enemy-tracker-line flex-break flex enemy-heading">
                <div class="name">
                    <select class="enemy-name" :id="'enemy' + index" :data-index="index" v-on:change="setEnemyValues">
                        <option>Name</option>
                        <option v-for="(enemy, optionIndex) in params.enemies" :key="optionIndex" :data-name="enemy.name" :data-level="enemy.level" :data-index="index" :data-number="enemy.number" :data-power="enemy.power" :data-health="enemy.health" :data-resistance="enemy.resistance" :selected="isSelected(index, enemy.name)">{{enemy.name}}</option>
                    </select>
                </div>
                <div class="level">
                    <label>Level</label>
                    <input v-model.number="params.values.enemies['levelEnemy'+index]" v-on:keyup="calculateLevel" type="number" pattern="\d*" v-on:focus="select" v-on:change="pushToLocalStorage">
                </div>
                <div class="number">
                    <label>Number</label>
                    <input v-model.number="params.values.enemies['numberEnemy'+index]"  v-on:keyup="setNumber" type="number" pattern="\d*" v-on:focus="select" v-on:change="pushToLocalStorage">
                </div>
            </div>
            <div class="enemy-tracker-line">
                <div>
                    <img class="tracker-icon" src="../assets/health.png" alt="Health" title="Health">
                </div>
                <div class="health tracker-section">
                    <button class="decrease-number number-change" :data-parameter="'healthEnemy'+index" v-on:click="decreaseNumber">-</button>
                    <input v-model.number="params.values.enemies['healthEnemy'+index]" type="number" pattern="\d*" v-on:focus="select" v-on:change="enemyHealthChange" class="tracker_input">
                    <button class="increase-number number-change" :data-parameter="'healthEnemy'+index" v-on:click="increaseNumber">+</button>
                </div>
            </div>
            <div class="enemy-tracker-line">
                <div>
                    <img class="tracker-icon" src="../assets/resistance.png" alt="Resitance" title="Resitance">
                </div>
                <div class="resistance tracker-section">
                    <button class="decrease-number number-change" :data-parameter="'resistanceEnemy'+index" v-on:click="decreaseNumber">-</button>
                    <input v-model.number="params.values.enemies['resistanceEnemy'+index]" type="number" pattern="\d*" v-on:focus="select" v-on:change="pushToLocalStorage" class="tracker_input">
                    <button class="increase-number number-change" :data-parameter="'resistanceEnemy'+index" v-on:click="increaseNumber">+</button>
                </div>
            </div>
            <div class="enemy-tracker-line">
                <div>
                    <img class="tracker-icon" src="../assets/power.png" alt="Power" title="Power">
                </div>
                <div class="power tracker-section">
                    <button class="decrease-number number-change" :data-parameter="'powerEnemy'+index" v-on:click="decreaseNumber">-</button>
                    <input v-model.number="params.values.enemies['powerEnemy'+index]" type="number" pattern="\d*" v-on:focus="select" v-on:change="pushToLocalStorage" class="tracker_input">
                    <button class="increase-number number-change" :data-parameter="'powerEnemy'+index" v-on:click="increaseNumber">+</button>
                </div>
            </div>
        </div>
        <button class="end-round" v-on:click="endRound">End Round</button>
    </section>
  </div>
</template>

<script>
document.addEventListener('gesturestart', function (e) {
    e.preventDefault();
});
let values = {
    herotrackers: [
        {
            health: 0,
            kills: 0,
            resistance: 0,
            resource: 0,
        },
        {
            health: 0,
            kills: 0,
            resistance: 0,
            resource: 0,
        },
        {
            health: 0,
            kills: 0,
            resistance: 0,
            resource: 0,
        },
        {
            health: 0,
            kills: 0,
            resistance: 0,
            resource: 0,
        },
        {
            health: 0,
            kills: 0,
            resistance: 0,
            resource: 0,
        },
    ],
    bosstrackers: [
        {
            health: 0,
            resistance: 0
        },
    ],
    enemies: {},
    viewCount: 1
}
let enemies = require('./Enemies.json')
let params = {
    values,
    enemies,
    enemyLines: 1
}
if(typeof(Storage) !== 'undefined'){
    let local = JSON.parse(localStorage.getItem('edgeguard_trackers'))
    if(local) params = local
    //localStorage.setItem('edgeguard_view', 1)
    let viewCount = Number(JSON.parse(localStorage.getItem('edgeguard_view')))
    if(viewCount) params.values.viewCount = viewCount
    console.log(params.values.viewCount)
}
export default {
    name: 'GameTrackers',
    data(){
        return {
            params
        }
    },
    watch:{
        enemyLines(){
            this.params.enemyLines <= 0 ? 1 : this.parmas.enemyLines
        }        
    },
    methods:{
        showTracker(index){
            let show = 0
            if(!index) show = 1
            if((index + 1) <= this.params.values.viewCount) show = 1
            return show
        },
        showButton(index, type){
            let show = 0
            if(type === 'remove'){
                if((index + 1) >= this.params.values.viewCount && index != 0) show = 1 
            } else {
                if((index + 1) >= this.params.values.viewCount && index < 3) {show = 1}
            }
            return show
        },
        increaseNumber(e){
            let parameter = e.target.dataset.parameter
            if(parameter.includes('Enemy')){
                if(this.params.values.enemies[parameter] == 99) return false
                this.params.values.enemies[parameter]++
            } else {
                let index = e.target.dataset.index !== undefined ? e.target.dataset.index : 0
                let trackerType = e.target.dataset.index !== undefined ? 'herotrackers' : 'bosstrackers'
                if(this.params.values[trackerType][index][parameter] == 99) return false
                this.params.values[trackerType][index][parameter]++
            }
            this.pushToLocalStorage()
        },
        decreaseNumber(e){
            let parameter = e.target.dataset.parameter
            if(parameter.includes('Enemy')){
                if(this.params.values.enemies[parameter] == 0) return false
                this.params.values.enemies[parameter]--
            } else {
                let index = e.target.dataset.index !== undefined ? e.target.dataset.index : 0
                let trackerType = e.target.dataset.index !== undefined ? 'herotrackers' : 'bosstrackers'
                if(this.params.values[trackerType][index][parameter] == 0) return false
                this.params.values[trackerType][index][parameter]--
            }
            this.pushToLocalStorage()
        },
        setEnemyValues(e){
            let data = e.target.options[e.target.selectedIndex].dataset
            let index = e.target.dataset.index
            this.params.values.enemies['nameEnemy' + index] = data.name ? data.name : false
            this.params.values.enemies['levelEnemy' + index] = data.level ? data.level : 0 
            this.params.values.enemies['powerEnemy' + index] = data.power ? data.power : 0
            this.params.values.enemies['healthEnemy' + index] = data.health ? data.health : 0
            this.params.values.enemies['resistanceEnemy' + index] = data.resistance ? data.resistance : 0 
            this.params.values.enemies['numberEnemy' + index] = data.number ? data.number : 0 
            if(document.querySelector('.enemy-tracker').querySelector('.enemy:last-of-type').querySelector('select').options.selectedIndex > 0){
                this.params.enemyLines++
            }
            this.pushToLocalStorage()
        },
        calculateLevel(e){
            if(e.target.value <= 0 || isNaN(e.target.value)) return false
            let options = e.target.closest('.enemy').querySelector('select').options
            let optionIndex = options.selectedIndex
            if(optionIndex == 0) return false
            let data = options[optionIndex].dataset
            if(e.target.value == 1){
                this.resetEnemy(options[optionIndex].dataset)
            } else {
                this.params.values.enemies['levelEnemy' + data.index] = e.target.value
                this.params.values.enemies['powerEnemy' + data.index] = this.levelCalculation(data.power, e.target.value)
                this.params.values.enemies['resistanceEnemy' + data.index] = this.levelCalculation(data.resistance, e.target.value)
                this.params.values.enemies['healthEnemy' + data.index] = this.levelCalculation(data.health, e.target.value)
            }
            this.pushToLocalStorage()
        },
        resetEnemy(data){
            this.params.values.enemies['levelEnemy' + data.index] = 1
            this.params.values.enemies['powerEnemy' + data.index] = data.power
            this.params.values.enemies['resistanceEnemy' + data.index] = data.resistance
            this.params.values.enemies['healthEnemy' + data.index] = data.health
        },
        setNumber(e){
            if(e.target.value <= 1 || isNaN(e.target.value)) return false
            let options = e.target.closest('.enemy').querySelector('select').options
            let optionIndex = options.selectedIndex
            if(optionIndex == 0) return false
            let data = options[optionIndex].dataset
            this.params.values.enemies['numberEnemy' + data.index] = e.target.value
            this.pushToLocalStorage()
        },
        levelCalculation(value, level){
            return level == 1 ? Math.floor((Number(value) + Number(Number(level) / 0.625)) - 1) : Math.floor(Number(value) + Number(Number(level) / 0.625))
            //may raise this at 5-10 level intervals (lower diviser)
        },
        select(e){
            e.target.select()
        },
        pushToLocalStorage(type = 'default'){
            if(typeof(Storage) !== 'undefined'){
                localStorage.setItem('edgeguard_trackers', JSON.stringify(this.params))
                if(type === 'viewcount') localStorage.setItem('edgeguard_view', JSON.stringify(this.params.values.viewCount))
            }
        },
        isSelected(index, name){
            return params.values.enemies['nameEnemy' + index] && params.values.enemies['nameEnemy' + index] === name ? true : false
        },
        endRound(){
            for(const enemy of document.querySelectorAll('.enemy-name')){
                let index = enemy.dataset.index
                let name = enemy.value
                if(name === 'Name' || name === '') continue
                let level = enemy.parentElement.nextElementSibling.querySelector('input').value
                console.log(level)
                for(const enemyData of this.params.enemies){
                    if(name !== enemyData.name) continue
                    this.params.values.enemies['resistanceEnemy' + index] = this.levelCalculation(enemyData.resistance, level) 
                }
            }
            this.pushToLocalStorage()
        },
        enemyHealthChange(e){
            let index = e.target.nextElementSibling.dataset.parameter.replace(/^\D+/g,'')
            if(e.target.value == 0){
                delete this.params.values.enemies['nameEnemy' + index]
                delete this.params.values.enemies['levelEnemy' + index] 
                delete this.params.values.enemies['powerEnemy' + index]
                delete this.params.values.enemies['healthEnemy' + index]
                delete this.params.values.enemies['resistanceEnemy' + index]
                delete this.params.values.enemies['numberEnemy' + index]
                let newObject = {}
                let count = 0
                for(const key of Object.keys(this.params.values.enemies)){
                    let newKey = key.replaceAll(/[0-9]/g, count)
                    newObject[newKey] = this.params.values.enemies[key]
                    if(key.search(/numberEnemy/) !== -1) count++
                }
                if(Object.keys(newObject).length !== 0) this.params.values.enemies = newObject
                if(this.params.enemyLines > 1){
                    this.params.enemyLines--
                }
            }
            this.pushToLocalStorage()
        },
        addTracker(e){
            e.preventDefault()
            let index = e.target.dataset.index
            if(index >= 3) return false
            e.target.parentElement.nextElementSibling.classList.remove('hide')
            if(this.params.values.viewCount < (this.params.values.herotrackers.length - 1)) this.params.values.viewCount++
            this.pushToLocalStorage('viewcount')
        },
        removeTracker(e){
            e.preventDefault()
            let index = e.target.dataset.index
            if(index <= 0) return false
            e.target.parentElement.classList.add('hide')
            if(this.params.values.viewCount > 1) this.params.values.viewCount--
            this.pushToLocalStorage('viewcount')
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}
input[type=number],.number-change{
    text-align: center;
    padding: 0.5rem;
    width: 50px;
    font-size: 2rem;
    border: none;
    border-radius: 5px;
    margin: 0.25rem;
    cursor: pointer;
    box-shadow: 0 0 6px rgba(0,0,0,.9);
}

.enemy-tracker-line [type=number],.enemy-tracker-line .number-change{
    padding: 0.15rem;
    width: 20px;
    font-size: 1.15rem;
    border-radius: 5px;
    margin: 0.15rem;
}


.life-trackers {
    text-align: center;
    max-width: 1800px;
    margin: auto;
}

.tracker {
    width: 350px;
    min-width: 350px;
    margin: 0 1.5rem 5rem 1.5rem;
    background-position: center center;
    background-size: cover;
    padding: 2rem;
    border-radius: 1rem;
    border:4px solid white;
    align-self: flex-start;
    position: relative;
    z-index: 0;
}

.add-remove-tracker, .add-remove-tracker:visited, .add-remove-tracker:focus {
    border: none;
    padding: 1rem;
    font-size: 1.15rem;
    margin-top: 3rem;
    border-radius: 0.5rem;
    background-color: #7d4cb0;
    color: #fff;
    cursor: pointer;
    position: absolute;
    top: -2rem;
    right: 1rem;
    z-index: 1;
}

.remove-tracker {
    right: auto;
    left: 1rem;
}

.hero-tracker {
    background-image: url(../assets/hero-tracker.jpg);
}

.hide {
    display: none;
}

.boss-tracker {
    background-image: url(../assets/boss-tracker.jpg);
}

.enemy-tracker {
    background-image: url(../assets/enemy-tracker.jpg);
}

.flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.enemy-tracker-line {
    min-width: 20%;
}

.enemy-tracker-line .tracker-section {
    margin: 0 .25rem;
}

.tracker-icon {
    max-width: 50px;
    max-height: 60px;
    margin: 5rem auto 1rem;
}

.tracker-icon:first-of-type {
    margin-top: 0;
}

.enemy-tracker-line .tracker-icon {
    max-width: 20px;
    max-height: 20px;
}

.selector {
    padding-top: 1rem;
    position: fixed;
    width: 5rem;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(255,255,255,.2);
    z-index: 2;
}

.selector .tracker-icon {
    margin: 1rem;
}

.flex-break {
    flex-basis: 100%;
    width: 100%;
    margin: 0.5rem auto;
}

select {
    width: 100%;
    padding: 0.5rem;
    border-radius: 5px;
    border: none;
    box-shadow: 0 0 6px rgba(0,0,0,.9);
}

label {
    color: black;
    margin: 0 0.5rem;
}

.enemy-heading {
    margin: 0 0 1rem 0;
}

.enemy {
    padding: 1.5rem 0;
    border-bottom: 1px solid rgba(0,0,0,0.65);
}

.enemy:first-of-type {
    padding-top: 0;
}

.enemy:last-of-type {
    border-bottom: none;
}
button {
    background-color: #fff;
    touch-action: none;
}
.end-round {
    padding: 1rem;
    display: inline-block;
    width: 100%;
    border-radius: 0.7rem;
    border: none;
    font-size: 1em;
    text-transform: uppercase;
    color: white;
    background-color: #762929;
    cursor: pointer;
}

@media screen and (max-width:600px){
    .tracker {
        width: 100%;
        min-width: 100%;
        padding: 2rem 0;
    }
    .selector {
        width: 100%;
        height: 5rem;
        padding-top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .name {
        width: 80%;
        margin-bottom: 1rem;
    }
    .tracker-icon {
        padding-top: 1rem;
    }
    .end-round {
        max-width: 80%;
    }
}

</style>
